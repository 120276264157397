import { z } from 'zod'

export const UsernameSchema = z
	.string({ required_error: 'Uživatelské jméno je povinné' })
	.min(3, { message: 'Uživatelské jméno musí mít alespoň 3 znaky' })
	.max(20, { message: 'Uživatelské jméno může mít maximálně 20 znaků' })
	.regex(/^[a-zA-Z0-9_]+$/, {
		message:
			'Uživatelské jméno může obsahovat pouze písmena, čísla a podtržítka',
	})
	// users can type the username in any case, but we store it in lowercase
	.transform(value => value.toLowerCase())

export const PasswordSchema = z
	.string({ required_error: 'Vyplňte prosím heslo' })
	.min(8, { message: 'Heslo musí mít alespoň 8 znaků' })
	.max(100, { message: 'Heslo může mít maximálně 100 znaků' })
export const NameSchema = z.string({ required_error: 'Jméno je povinné' })
export const SurnameSchema = z.string({ required_error: 'Příjmení je povinné' })
export const EmailSchema = z
	.string({ required_error: 'Vyplňte prosím email' })
	.email({ message: 'Email je neplatný' })
	.min(3, { message: 'Email musí mít alespoň 3 znaky' })
	.max(100, { message: 'Email může mít maximálně 100 znaků' })
	// users can type the email in any case, but we store it in lowercase
	.transform(value => value.toLowerCase())
export const PhoneSchema = z.string({
	required_error: 'Vyplňte prosím telefonní číslo',
})

export const PasswordAndConfirmPasswordSchema = z
	.object({ password: PasswordSchema, confirmPassword: PasswordSchema })
	.superRefine(({ confirmPassword, password }, ctx) => {
		if (confirmPassword !== password) {
			ctx.addIssue({
				path: ['confirmPassword'],
				code: 'custom',
				message: 'Hesla se musí shodovat',
			})
		}
	})

export const AddressSchema = z
	.string({ required_error: 'Vyplňte prosím adresu' })
	.min(1, { message: 'Adresa musí mít alespoň 1 znak' })

export const IcoSchema = z
	.string({ required_error: 'Vyplňte prosím IČO' })
	.min(8, { message: 'IČO musí mít 8 znaků' })
	.max(8, { message: 'IČO musí mít 8 znaků' })

export const DicSchema = z
	.string({ required_error: 'Vyplňte prosím DIČ' })
	.min(10, { message: 'DIČ musí mít 10 znaků' })
	.max(10, { message: 'DIČ musí mít 10 znaků' })

export const ImageSchema = z.object({
	uri: z.string(),
	name: z.string(),
	type: z.string(),
})
